import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ViewName, VIEW_NAMES } from '../../../views/manifest'

export const selectViews = (state: any): Record<ViewName, ViewState> => state.views.views;

interface WasJustUpdatedPayload {
  viewName: ViewName
}

const WasJustUpdatedReducer = {
    reducer: (state: any, action: PayloadAction<WasJustUpdatedPayload>) => {
       return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    lastUpdated: new Date()
                }
            }
        }
    },
    prepare: (viewName: ViewName) => ({
      payload: {
        viewName: viewName
      }
    })
}

interface UpdateViewExistsPayload {
  viewName: ViewName
  exists: boolean | "Unknown"
}

const UpdateViewExistsReducer = {
    reducer: (state: any, action: PayloadAction<UpdateViewExistsPayload>) => {
        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    exists: action.payload.exists
                }
            }
        }
    },
    prepare: (viewName: ViewName, exists: boolean | "Unknown") => ({
        payload: {
            viewName: viewName, exists: exists
        }
    })
}

interface UpdateRowscnPayload {
    viewName: ViewName
    rowscn: number | "Unknown"
}

const UpdateRowscnReducer = {
    reducer: (state: any, action: PayloadAction<UpdateRowscnPayload>) => {

        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    rowscn: action.payload.rowscn
                }
            }
        }
    },
    prepare: (viewName: ViewName, rowscn: number | "Unknown") => ({
        payload: {
            viewName: viewName, rowscn: rowscn
        }
    })
}

interface UpdateRowCountPayload {
  viewName: ViewName,
  rowCount: number | "Unknown"
}

const UpdateRowCountReducer = {
    reducer: (state: any, action: PayloadAction<UpdateRowCountPayload>) => {
      return {
        ...state,
        views: {
          ...state.views,
          [action.payload.viewName]: {
            ...state.views[action.payload.viewName],
            rowsCount: action.payload.rowCount
          }
        }
      }
    },
    prepare: (viewName: ViewName, rowCount: number | "Unknown") => ({
      payload: {
        viewName: viewName, rowCount: rowCount
      }
    })
}


interface ActionIsStartedPayload {
    viewName: ViewName
    action: string
}

const ActionIsStartedReducer = {
    reducer: (state: any, action: PayloadAction<ActionIsStartedPayload>) => {
        let startedActions = [...state.views[action.payload.viewName].actionsInProgress]
        startedActions.push(action.payload.action)

        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    actionsInProgress: startedActions
                }
            }
        }
    },
    prepare: (viewName: ViewName, action: string) => ({
        payload: {
            viewName: viewName, action: action
        }
    })
}

interface ActionIsCompletedPayload {
    viewName: ViewName,
    action: string
}

const ActionIsCompletedReducer = {
    reducer: (state: any, action: PayloadAction<ActionIsCompletedPayload>) => {
        let startedActions = [...state.views[action.payload.viewName].actionsInProgress]
        let completedActions = [...state.views[action.payload.viewName].actionsSucceded]

        startedActions = startedActions.filter(s => s != action.payload.action)
        completedActions.push(action.payload.action)

        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    actionsInProgress: startedActions,
                    actionsSucceded: completedActions
                }
            }
        }
    },
    prepare: (viewName: ViewName, action: string) => ({
        payload: {
            viewName: viewName, action: action
        }
    })
}

interface ActionIsFailedPayload {
    viewName: ViewName
    action: string
    err: string
}

const ActionIsFailedReducer = {
    reducer: (state: any, action: PayloadAction<ActionIsFailedPayload>) => {
        let startedActions = [...state.views[action.payload.viewName].actionsInProgress]
        let failedActions = [...state.views[action.payload.viewName].actionFailed]

        startedActions = startedActions.filter(s => s != action.payload.action)
        failedActions.push(`${action.payload.action} : ${action.payload.err}`)

        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    actionsInProgress: startedActions,
                    actionsFailed: failedActions
                }
            }
        }
    },
    prepare: (viewName: ViewName, action: string, err: string) => ({
        payload: {
            viewName: viewName, action: action, err: err
        }
    })
}

interface ClearActionsPayload {
    viewName: ViewName
}

const ClearActionsReducer = {
    reducer: (state: any, action: PayloadAction<ClearActionsPayload>) => {
        return {
            ...state,
            views: {
                ...state.views,
                [action.payload.viewName]: {
                    ...state.views[action.payload.viewName],
                    actionsInProgress: [],
                    actionsSucceded: [],
                    actionsFailed: []
                }
            }
        }
    },
    prepare: (viewName: ViewName) => ({
        payload: {
            viewName: viewName
        }
    })
}

export interface ViewState {
    exists: boolean | "Unknown"
    rowscn: number | "Unknown"
    rowsCount: number | "Unknown"
    actionsInProgress: string[]
    actionsSucceded: string[]
    actionsFailed: string[]
    lastUpdated: Date | "Never"
}

function defaultViewState(): ViewState {
  return {
    exists: "Unknown",
    rowscn: "Unknown",
    rowsCount: "Unknown",
    actionsInProgress: [],
    actionsSucceded: [],
    actionsFailed: [],
    lastUpdated: "Never"
  }
}

export interface ViewSliceState {
    views: Record<ViewName, ViewState>
}

const initialState: ViewSliceState = {
    views: {
        "game_orders": defaultViewState(),
        "test_view": defaultViewState(),
        "jobs": defaultViewState()
    },
}


export const viewsSlice = createSlice({
    name: 'views',
    initialState,
    reducers: {
        updateViewExists: UpdateViewExistsReducer,
        updateRowscn: UpdateRowscnReducer,
        updateRowCount: UpdateRowCountReducer,
        actionIsStarted: ActionIsStartedReducer,
        actionIsCompleted: ActionIsCompletedReducer,
        actionIsFailed: ActionIsFailedReducer,
        clearActions: ClearActionsReducer,
        wasJustUpdated: WasJustUpdatedReducer,
    }
})

export const {updateViewExists, updateRowscn, updateRowCount, actionIsStarted, actionIsCompleted, actionIsFailed, clearActions, wasJustUpdated} = viewsSlice.actions
