import React from 'react';
import { ViewName } from '../../../views/manifest'
import { ViewState, clearActions } from '../store/viewsSlice'
import { useDispatch, useSelector } from 'react-redux';

import socket from '../socket'
import { ScheduleComponent } from './Schedule'

export interface ViewComponentProps {
    viewName: ViewName
    viewState: ViewState
}

export const ViewComponent: React.FC<ViewComponentProps> = ({viewName, viewState}) => {

    const dispatch = useDispatch();  

    const updateButtonHandler = () => {
      socket.emit("runUpdateProgram", viewName)
    }

    const getStatusButtonHandler = () => {
      socket.emit("runGetViewStatusProgram", viewName)
    }

    const createButtonHandler = () => {
      socket.emit("runCreateViewProgram", viewName)
    }

    const dropButtonHandler = () => {
      socket.emit("runDropViewProgram", viewName)
    }

    const clearActionsHandler = () => {
      dispatch(clearActions(viewName))
    }

    const makeScrollBox = (title: string, content: string) => {
        return (
        <div>
            <h4>{title}</h4>
            <div
                style={{
                    height: '120px',
                    width: '300px',
                    border: '1px solid #ccc',
                    overflow: 'auto',
                    margin: '10px' 
                }}
            >
                {content}
            </div>
        </div>
        )
    }

    const lastUpdatedStr = (val: Date | "Never") => {
      if (val == "Never") {
        return "Never"
      } else {
        return val.toString()
      }
    }

    return (
        <div>
            <h3>View {viewName} at ROWSCN {viewState.rowscn}</h3>
            <h4>Last Updated: {lastUpdatedStr(viewState.lastUpdated)}</h4>
            {viewState.exists != "Unknown" && <h4>View Exists in Mirror: {viewState.exists.toString()}</h4>}
            {viewState.rowsCount != "Unknown" && <h4>Row Count: {viewState.rowsCount}</h4>}
            <button onClick={createButtonHandler}>Create</button>
            <button onClick={dropButtonHandler}>Drop</button>
            <button onClick={updateButtonHandler}>Update</button>
            <button onClick={clearActionsHandler}>Clear Actions</button>
            <button onClick={getStatusButtonHandler}>Get View Status</button>

            <ScheduleComponent viewName={viewName}></ScheduleComponent>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {makeScrollBox("Actions in Progress", viewState.actionsInProgress.join("\n"))}
                {makeScrollBox("Completed Actions", viewState.actionsSucceded.join("\n"))}
                {makeScrollBox("Failed Actions", viewState.actionsFailed.join("\n"))}
            </div>
        </div>
    )
} 

